
import React from 'react';
import { Link } from 'gatsby';

export const Feature50DataSource = {
  wrapper: { className: 'home-page-wrapper content7-wrapper' },
  page: { className: 'home-page content7' },
  OverPack: {},
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'title',
        children: (
          <>
            <p>What&apos;s Sortd All About?</p>
          </>
        ),
        className: 'title-h1',
      },
      {
        name: 'content',
        children: (
          <>
            <p>Features and Benefits</p>
          </>
        ),
      },
    ],
  },
  tabsWrapper: { className: 'content7-tabs-wrapper' },
  block: {
    children: [
      {
        name: 'block0',
        tag: {
          className: 'content7-tag',
          text: {
            children: (
              <>
                <p>Plan Change</p>
              </>
            ),
            className: 'content7-tag-name',
          },
          icon: { children: 'edit' },
        },
        content: {
          className: 'content7-content',
          text: {
            className: 'content7-text',
            md: 14,
            xs: 24,
            children: (
              <>
                <span>
                  <h3>Plan Organisational Change Maturity</h3>
                  <p>
                    Assess current organisational change maturity using the
                    <Link to='/blog/sortd-maturity-framework'> Sortd Organisational Change Maturity Model</Link>. Once you know
                    where you are, create an action plan to uplift
                    organisational capability, and track it to realisation.
                  </p>
                  <br />

                  <h3>
                    <span style={{backgroundColor: 'rgba(0, 0, 0, 0)', '-webkit-text-fill-color': 'inherit'}}>
                      Only Use the Right Tools
                    </span>
                  </h3>
                  <p>
                    <span style={{backgroundColor: 'rgba(0, 0, 0, 0)', color: 'rgb(49, 70, 89)', fontSize: '14px', '-webkit-text-fill-color': 'inherit'}}>
                      With the project sizing wizard you can ensure that your teams are using the correct tools and following the correct methodology, no matter how big or small the project is.
                    </span>
                  </p>
                </span>
              </>
            ),
          },
          img: {
            src: 'maturity.png',
            className: 'content7-img',
            children:
              'https://firebasestorage.googleapis.com/v0/b/sortd-portal.appspot.com/o/blogposts%2Fmaturity.png?alt=media&token=00d0db93-76df-499e-af0f-62f9b590fc1b',
            md: 10,
            xs: 24,
          },
        },
      },
      {
        name: 'block1',
        tag: {
          className: 'content7-tag',
          icon: { children: 'reconciliation' },
          text: {
            className: 'content7-tag-name',
            children: (
              <>
                <p>Manage Change</p>
              </>
            ),
          },
        },
        content: {
          className: 'content7-content',
          text: {
            className: 'content7-text',
            md: 14,
            xs: 24,
            children: (
              <>
                <span>
                  <h3>Manage Tasks</h3>
                  <p>
                    Sortd contains everything you&apos;d expect from an enterprise ready task management system, kanban boards, time tracking, reporting, and more. 
                    Link tasks to projects, changes, and assessments to get an understanding of your organisation like never before.
                  </p>
                  <br />
                  <h3>Give Your Organisation a Voice</h3>
                  <p>
                    Traditional project and task management systems ignore the number one factor in successful change - people. 
                    The tight integration of Sortd&apos;s assessment and survey features into the project lifecycle enable you to hear it from the people who matter first.
                    Use a range of our prebuilt assessments such as Business Readiness Assessments, Change Impact Assessments, or Benefits Realisation Assessments, or create your own.
                  </p>
                </span>
              </>
            ),
          },
          img: {
            className: 'content7-img',
            md: 10,
            xs: 24,
            src: 'taskboard.png',
            children:
              'https://zos.alipayobjects.com/rmsportal/xBrUaDROgtFBRRL.png',
          },
        },
      },
      {
        name: 'block2',
        tag: {
          className: 'content7-tag',
          text: {
            children: (
              <>
                <p>Repeat Change</p>
              </>
            ),
            className: 'content7-tag-name',
          },
          icon: { children: 'line-chart' },
        },
        content: {
          className: 'content7-content',
          text: {
            className: 'content7-text',
            md: 14,
            xs: 24,
            children: (
              <>
                <span>
                  <h3>See Results in Real Time</h3>
                  <p>
                    With a range of different dashboards and reports, you&apos;ll be able to easily present change activities and progress from the C-Suite through to front-line staff with ease.
                  </p>
                  <br />
                  <h3>Create Project Templates</h3>
                  <p>
                    We get it, all organisations are different, however that doesn&apos;t mean that each project is completely unrelated. 
                    That&apos;s why with Sortd, we&apos;ve introduced project templates to allow you to standardise new projects using predefined tasks, milestones, and change activities.
                    No more spending hours and hours copying data from one static file to another.
                  </p>
                </span>
              </>
            ),
          },
          img: {
            className: 'content7-img',
            md: 10,
            xs: 24,
            src: 'impact.png',
            children:
              'https://zos.alipayobjects.com/rmsportal/xBrUaDROgtFBRRL.png',
          },
        },
      },
    ],
  },
};
