import React from 'react';
import TweenOne from 'rc-tween-one';
import OverPack from 'rc-scroll-anim/lib/ScrollOverPack';
import { Tabs, Icon, Row, Col } from 'antd';
import Parallax from 'rc-scroll-anim/lib/ScrollParallax';
import { StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const {TabPane} = Tabs;

function renderImage(file) {
  return (
    <Img fluid={file.node.childImageSharp.fluid} />
  )
}

class Content7 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      current: 1,
    };
  }

  onChange = (key) => {
    this.setState({ current: parseFloat(key) });
  };

  getBlockChildren = (item, i) => {
    const { tag, content } = item;
    const { text, img } = content;
    const textChildren = text.children;
    const { icon } = tag;
    const iconChildren = icon.children;
    const tagText = tag.text;
    return (
      <TabPane
        key={i + 1}
        tab={
          <div className={tag.className}>
            <Icon type={iconChildren} className={icon.className} />
            <div {...tagText}>{tagText.children}</div>
          </div>
        }
        className={item.className}
      >
        <TweenOne.TweenOneGroup
          enter={{
            y: 0,
            x: 90,
            delay: 200,
            opacity: 0,
            type: 'from',
            ease: 'easeOutQuad',
          }}
          leave={null}
          component=""
        >
          {this.state.current === i + 1 && (
            <Row
              key="content"
              className={content.className}
              gutter={content.gutter}
            >
              <Col className={text.className} xs={text.xs} md={text.md}>
                {textChildren}
              </Col>
              <Col className={img.className} xs={img.xs} md={img.md}>
              <StaticQuery
            query={graphql`
            query {
              images: allFile(filter: { sourceInstanceName: { eq: "images" } }) {
                edges {
                  node {
                    extension
                    relativePath
                    childImageSharp {
                    fluid(maxWidth: 600) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
              `}
            render={data => {
              const image = data.images.edges.find(
                im => im.node.relativePath === img.src
              )
              return(renderImage(image))
            }} />
              </Col>
            </Row>
          )}
        </TweenOne.TweenOneGroup>
      </TabPane>
    );
  };

  render() {
    const { ...props } = this.props;
    const { dataSource } = props;
    delete props.dataSource;
    const tabsChildren = dataSource.block.children.map(this.getBlockChildren);
    return (
      <div {...props} {...dataSource.wrapper}>
      {!props.isMobile && (
        <Parallax
          className="page1-bg"
          animation={{
            translateY: 200,
            ease: 'linear',
            playScale: [0, 1.65],
          }}
          location="page1-wrapper"
        >
          Features
        </Parallax>
      )}

        <div {...dataSource.page}>
          <div {...dataSource.titleWrapper}>
          <h2>
          What can I do with <span>Sortd</span>{' '}
        </h2>
        <div className="title-line-wrapper">
          <div className="title-line" />
        </div>
          </div>

          <OverPack {...dataSource.OverPack}>
            <TweenOne.TweenOneGroup
              key="tabs"
              enter={{
                y: 0,
                x: 90,
                opacity: 0,
                delay: 200,
                type: 'from',
              }}
              leave={{ y: 0, x: 180, opacity: 0 }}
              {...dataSource.tabsWrapper}
            >
              <Tabs
                key="tabs"
                delay={200}
                onChange={this.onChange}
                activeKey={`${this.state.current}`}
                {...dataSource.block}
              >
                {tabsChildren}
              </Tabs>
            </TweenOne.TweenOneGroup>
          </OverPack>
        </div>
      </div>
    );
  }
}

export default Content7;
