import React from 'react';
import QueueAnim from 'rc-queue-anim';
import TweenOne from 'rc-tween-one';
import { Carousel } from 'antd';
import { FormattedMessage } from 'react-intl';
import 'html5-device-mockups';
import { StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import EmailListForm from './MailChimpQuick';

const Banner = ({isMobile}) => {
  return (
    <div className="banner-wrapper">
      <TweenOne animation={{ opacity: 1 }} height="100%" className="banner-image-wrapper">
        <div className="device-wrapper" style={{ maxWidth: '100%' }}>
          <div
            className="device"
            data-device="MacbookPro"
            data-orientation="portrait"
            data-color="black"
          >
          <StaticQuery
            query={graphql`
            query {
              readiness: file(relativePath: { eq: "Readiness.png" }) {
                childImageSharp {
                  # Specify the image processing specifications right in the query.
                  # Makes it trivial to update as your page's design changes.
                  fluid(maxWidth: 700) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
              snapshot: file(relativePath: { eq: "Snapshot.png" }) {
                childImageSharp {
                  # Specify the image processing specifications right in the query.
                  # Makes it trivial to update as your page's design changes.
                  fluid(maxWidth: 700) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
              sizing: file(relativePath: { eq: "Sizing Wizard.png" }) {
                childImageSharp {
                  # Specify the image processing specifications right in the query.
                  # Makes it trivial to update as your page's design changes.
                  fluid(maxWidth: 700) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }`}
            render={data => (
            <div className="screen">
              <Carousel className="home-banner-anim" autoplay>
                <div padding="12px">
                  <Img fluid={data.snapshot.childImageSharp.fluid} />
                </div>
                <div padding="12px">
                  <Img fluid={data.readiness.childImageSharp.fluid} />
                </div>
                <div padding="12px">
                  <Img fluid={data.sizing.childImageSharp.fluid} />
                </div>
              </Carousel>
            </div>
          )} />
          </div>
        </div>
      </TweenOne>
      <QueueAnim className="banner-title-wrapper" type={isMobile ? 'bottom' : 'right'}>
        <div key="line" className="title-line-wrapper">
          <div className="title-line" style={{ transform: 'translateX(-64px)' }} />
        </div>
        <h1 key="h1">SORTD</h1>
        <p key="content">
          <FormattedMessage id="app.home.slogan" />
        </p>
        <div className="signupLabel">
          Be one of the first to try it - sign up now for early access
        </div>
        <div key="button" className="button-wrapper">
          <EmailListForm />
        </div>
      </QueueAnim>

    </div>
  );
}



export default Banner;
