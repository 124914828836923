import React from 'react';
import { injectIntl } from 'react-intl';
import { Helmet } from 'react-helmet';
import { Feature50DataSource } from './features-data/data.source';
import Banner from './Banner';
import Page1 from './Page1';
import Features from './Features';


function Home(props) {
  const { intl } = props;
  return (
    <>
      <Helmet>
        <title>{`Sortd - ${intl.formatMessage({
          id: 'app.home.slogan',
        })}`}</title>
        <meta
          name="description"
          content={`Sortd - ${intl.formatMessage({
            id: 'app.home.slogan',
          })}`}
        />
      </Helmet>
      <div className="home-wrapper">
        <Banner {...props} />
        <Page1 {...props} />
        <Features
          id="Feature5_0"
          key="Feature5_0"
          dataSource={Feature50DataSource}
        />
      </div>
    </>
  );
}

export default injectIntl(Home);
