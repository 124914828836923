import React, {useState} from 'react';
import { Form, Button, Input, Tag } from 'antd';
import addToMailchimp from 'gatsby-plugin-mailchimp';
import { MailOutlined } from '@ant-design/icons';

const EmailListForm: React.FunctionComponent<{}> = () => {

  const [email, setEmail] = useState('');
  const [status, setStatus] = useState('');
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);

  const handleSubmit = () => {
    setLoading(true)
    addToMailchimp(email)
      .then((data) => {
        setStatus(data.result)
        setMessage(data.msg)
        setLoading(false)
      })
      .catch((error: Error) => {
        setLoading(false)
        console.log(error)
      });
  };

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.currentTarget.value);
  };

  return (
    <Form layout="inline" onFinish={handleSubmit}>
      <Form.Item 
        name='email'
        rules={[{ required: true, message: 'Email Address is required!' }]}
      >
          <Input
            size='large'
            
            prefix={<MailOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
            placeholder="Email Address"
            onChange={handleEmailChange}
          />
      </Form.Item>
      <Form.Item>
        <Button size='large' type="primary" htmlType='submit' loading={loading}>
          Get Early Access
        </Button>
      </Form.Item>
      {status === 'error' && (
        <Tag style={{ marginTop: '12px' }} color="volcano">
          <div dangerouslySetInnerHTML={{ __html: message }} />
        </Tag>
      )}
      {status === 'success' && (
        <Tag style={{ marginTop: '12px' }} color="geekblue">
          Thanks! We&apos;ll be in touch shortly with next steps.
          <img height="1" width="1" style={{display:'none'}} alt="" src="https://px.ads.linkedin.com/collect/?pid=717891&conversionId=2351620&fmt=gif" />
        </Tag>
      )}
    </Form>
  );
};

export default EmailListForm;